.checkIn {
  height: 100vh;
  position: absolute;

  .wrapper {
    display: flex;
    height: 100%;

    .reservation {
      width: 40vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 48px;
    }
    .checkInControls {
      width: 60vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 48px;

      h2 {
        position: absolute;
        top: 25px;
      }

      .checkInButton, .cancelButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 250px;
        height: 100px;
        padding: 20px 15px;
        margin-right: 45px;
        font-size: 18px;
        font-weight: bolder;
        color: #FFFFFF;
        p {
          font-size: 18px;
        }
      }

      .checkInButton {
        background-color: green;
      }

      .cancelButton {
        background-color: red;
        margin-right: 0;
      }

      .logoutButton {
        position: absolute;
        bottom: 25px;
        right: 25px;
      }
    }
  }
}

.disabled {
  opacity: 0.3;
}