.hourlyPrediction {
  padding: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: hidden;
  margin-top: -5px;

  .chartsWrapper {
    max-height: 80vh;
    height: 70vh;
    overflow: hidden;
    margin-top: -10px;
  }

  .dailyPrediction {
    width: 95%;
    margin-top: 5px;
    text-align: center;
    position: absolute;
  }

}