.rotating {
  svg,img {
    animation: loading 1.5s linear infinite;

    @keyframes loading {
        0% {
            transform: rotate(0);
        }

        100% {
            transform: rotate(360deg);
        }
    }
  }
}