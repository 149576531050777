.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 0px 15px;

  .marinaName {
    margin-left: 180px;
    margin-top: 15px;

    h5 {
      min-width: 73px;
    }
  }

  .user {
    display: flex;
    align-items: center;
    right: 15px;
    top: 15px;
  
    h6 {
      margin-top: 20px;
    }

  }
  
  .time {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -10px;
    top: -15px;

    .logo {
      width: 120px;
      height: 120px;
    }
  }

  .avatar {
    background-color: blueviolet;
    border-radius: 25px;
    width: 50px;
  }

}

@media only screen and (min-height: 740px) {
  .time {
    position: absolute;
    left: -20px !important;
    top: -25px !important;

    .logo {
      width: 160px !important;
      height: 160px !important;
    }
  }
  
  h3 {
    font-size: 36px;
  }
}