.hourlyOccupation {
  padding: 25px;
  padding-top: 5px;
  overflow: hidden;

  .vesselWrapper {
    .vesselColor {
      display: block;
      width: 25px;
      height: 5px;
      border-radius: 0.25rem;
      margin-top: 5px;
    }
  }

  .hour {
    padding: 10px 6px;
    width: stretch;
    text-align: center;
    vertical-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;


    span {
      max-height: 60px;
    }
    .vesselWrapper {
      max-height: 60px;
      min-height: 60px;
    }
    
    &.selected {
      color: #FFFFFF;
      background-color: var(--primary);
    }

    &:first-child {
      padding-left: 5px;
    }
  
    &:last-child {
      padding-right: 5px;
    }

    .availableHour {
      margin-top: 25px;
      color: greenyellow;
      font-weight: bold;
      padding-bottom: 15px;
    }
  }
}

.hourWrapper {
  transition: all ease-in-out .2s;
  border-radius: 4px;

  &:last-child {
    border-radius: 4px;
  }
  .grayLine {
    background-color: darken(#586A88, 10%);
    transition: all ease-in-out .2s;
  } 

}