.marina {
  
  .marinaWrapper {
    padding: 0 25px;

    .controls {
      display: flex;
      justify-content: space-between;
      bottom: 25px;
      z-index: 9999;
      margin-top: -25px;
    } 
  
    .vesselWrapper {
      .vesselColor {
        display: block;
        width: 30px;
        height: 10px;
        border-radius: 0.25rem;
        margin-bottom: 10px;
        margin-top: 0;
      }
    }
  
    .statusButtonsWrapper {
      display: flex;
  
      .statusButton {
        margin-right: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        border: solid 1px #EBEBEB;
        height: 50px;
        width: 75px;
        border-radius: 6px;
  
        &.active {
          border: solid 2px #3366FF;
        }
  
        span {
          margin-bottom: 15px;
          font-size: 22px;
        }
      }
    }
  }

}

.rotating {
  svg,img {
      animation: loading 2s linear infinite;

      @keyframes loading {
          0% {
              transform: rotate(0);
          }

          100% {
              transform: rotate(360deg);
          }
      }

  }
}

.departureRequested {
  background-color: #219653;
  padding: 5px;
  border-radius: 6px;
  font-weight: bold;
}
.arrivesRequested {
  background-color: red;
  padding: 5px;
  border-radius: 6px;
  font-weight: bold;
}

.grid {
  height: 420px;
  width: 100%;
}

@media only screen and (min-height: 730px) and (min-width: 1000px) {
  .grid {
    height: 540px !important;
  }
}


@media only screen and (max-height: 600px) and (min-width: 950px) {
  .grid {
    height: 460px !important;
  }
}

@media only screen and (max-height: 600px) and (min-width: 1000px) {
  .grid {
    height: 420px !important;
  }

}