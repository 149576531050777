.reservation {
  overflow: hidden;

  .confirmReservation {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 25px;
    padding-right: 90px;
    margin-top: 35px;

    &.isCustomer {
      margin-top: -5px;
    }
  }
}

@media only screen and (min-height: 650px) {
  .confirmReservation {
    margin-top: 110px !important;
  }
}

.noVessel.noDeparture {
  margin-top: 300px !important;
}

.noVessel {
  margin-top: 220px !important;
}

.noDeparture {
  margin-top: 65px !important;
}

@media only screen and (min-height: 740px) {
  .confirmReservation {
    margin-top: 165px !important;
  }
  .confirmReservation.isCustomer {
    margin-top: 115px !important;
  }
  .noVessel.noDeparture {
    margin-top: 430px !important;
  }
  .noVessel {
    margin-top: 350px !important;
  }
  
  .noDeparture {
    margin-top: 195px !important;
  }
}

@media only screen and (min-height: 1000px) {
  .confirmReservation {
    margin-top: 195px !important;
  }
  .confirmReservation.isCustomer {
    margin-top: 145px !important;
  }
  .noVessel.noDeparture {
    margin-top: 460px !important;
  }
  .noVessel {
    margin-top: 380px !important;
  }
  
  .noDeparture {
    margin-top: 205px !important;
  }
}

.imageWrapper {
  transition: all ease-in-out .5s;
  display: flex;
  justify-content: center;
  height: 90px;
  max-height: 90px;

  img {
    width: 100%;
    max-height: 80px;
    object-fit: contain;
    margin: auto;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    margin-bottom: 30px;
    border: solid 5px white
  }
}

.imagePlaceholder {
  max-height: 80px;
  object-fit: contain;
  margin: auto;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  margin-bottom: 30px;
  border: solid 5px white;
  background-color: white;
  margin-left: 20px;
  border-radius: 4px;
}