.modal {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);

  &:focus-visible {
    outline: none;
  }

  .header {
    position: absolute;
    top: 0%;
    left: 0;
    right: 0;
    height: 65px;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: var(--border);
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
    color: #FFFFFF;

    &.success {
      background-color: #219653;
    }

    .closeIcon {
      position: absolute;
      right: 0;
      margin-right: 15px;
      cursor: pointer;
    }

    p {
      font-size: 20px;
      font-weight: bold;
    }

  }

  .content {
    padding-top: 65px;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: max-content;
    min-height: 215px;
    color: #000000;
  }
}


.MuiBox-root:focus-visible {
  outline: none;
}