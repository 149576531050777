.totem {
  height: 100vh;

  .wrapper {
    display: flex;
    height: 100%;

    .logo {
      width: 50vw;
      background-color: #000000;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 48px;
    }
    .content {
      width: 50vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 48px;

      .form {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
      }

      .totemOptions {
        display: flex;
        width: 400px;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        padding: 20px 15px;
        font-size: 18px;
        font-weight: bolder;
        color: #FFFFFF;
        margin-top: 25px;
      }

    }
  }
}