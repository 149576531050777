.subReasonModal {
  height: 650px;
  width: inherit;

  .infoTitle {
    color: var(--primary);
  }

  .info {
    color: #868585;
  }

  h5 {
    color: var(--primary);
  }

  .newDescription {
    min-width: 475px;
  }

  .switch {


    label {
      margin-right: 0;
      min-width: 120px;
    }
  }

}