.foundVessels {
  span {
    min-width: 135px;
    padding: 5px;
    &:first-child {
      min-width: 30px;
    }
    &:nth-child(3) {
      min-width: 50px;
    }
  }
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
}

.section {
  width: 100%;
  margin-top: 25px;

  .sectionTitle {
    border-bottom: solid 1px #EBEBEB;
    margin-bottom: 15px;
    color: gray;
  }

}

.emergencyContact {
  margin-top: 68px;
}
