.checkInCard {
  background-color: #FFFFFF;
  height: 90vh;
  width: 35vw;
  border-radius: 12px;
  position: absolute;
  font-size: .5rem;
  color: #000000;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    padding: 15px;
    color: #FFFFFF;
    background-color: #000000;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

    .logo {
      width: 120px;
      height: 120px;
      position: absolute;
      top: -18px;
      left: -18px;
    }
  }

  .reservationInfo {
    border-bottom: dashed 2px #000000;
    padding-bottom: 15px;
    padding: 15px;
    padding-top: 0;
    
    .separator {
      height: 1px;
      width: 0px;
      border-top: solid 1px #000000;
      animation: animate 4s linear infinite;
      position: absolute;
      margin-right: 75px;
      right: 75px;
      width: 180px;
      
      &:nth-of-type(2) {
        margin-left: 75px;
        left: 15px;
      }
    }

    .vesselWrapper {
      background-color: #FFFFFF;
      border-radius: 50%;
      position: relative;

      .vesselColor {
        display: block;
        width: 40px;
        height: 10px;
        border-radius: 0.25rem;
        border: solid .5px #000000;
  
      }

    }

  
  }

  .vesselInfo, .ownerInfo {
    border-bottom: dashed 2px #000000;
    padding: 15px;

  }

  .vesselGarage {
    width: 120px;
    // height: 60px;
    border: solid 2px #000000;
    border-radius: 6px;
    background-color: #20b2aa;
    color: #000000;
    font-weight: 900;
    
    div {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        &:first-of-type {
          background-color: #FFFFFF;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
          width: 100%;
          text-align: center;
          margin-top: 0;
          padding: 5px;
        }
        margin-top: 5px;
      }
    }
  }
  
  .checkinStatus {
    position: inherit;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    
    h5 {
      background-color: #ffff00;
      color: #000000;
      width: 100%;
      padding: 15px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      margin-bottom: 0;

    }
    &.confirmed {
      h5 {
        background-color: #219653;
        color: #FFFFFF;
      }
    }
    &.pending {
      h5 {
        background-color: #ffff00;
        color: #000000;
      }
    }
    &.finished {
      h5 {
        background-color: #219653;
        color: #FFFFFF;
      }
    }
    &.canceled {
      h5 {
        background-color: #ff0000;
        color: #FFFFFF;
      }
    }
  }

  .section {
    margin-top: -25px;
  }

  .ticketCut {
    span {
      position: relative;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: var(--background-color);
      bottom: 22px;
      left: 25px;

      &:first-of-type {
        left: -25px;
      }

    }
  }
}

@keyframes animate {
  0% {
    width: 0px;
  }

  50% {
    width: 180px;
  }

  100% {
    width: 0px;
  }
}

@media only screen and (min-height: 650px) {
  .section {
    &:last-of-type {
      margin-top: 15px !important;
    }
  }

  @keyframes animate {
    0% {
        width: 0px;
    }
  
    50% {
      width: 260px;
    }
  
    100% {
      width: 0px;
    }
  }
}

@media only screen and (min-height: 740px) {
  .section {
    &:last-of-type {
      margin-top: 15px !important;
    }
  }

  @keyframes animate {
    0% {
        width: 0px;
    }
  
    50% {
      width: 180px;
    }
  
    100% {
      width: 0px;
    }
  }
}

@media only screen and (min-height: 1000px) {
  @keyframes animate {
    0% {
        width: 0px;
    }
  
    50% {
      width: 200px;
    }
  
    100% {
      width: 0px;
    }
  }
}